<template>
	<div class="home-mobile" v-cloak v-if="isMobile&&homeItem">
		<div class="video-div" @click="clickVideo">
			<video ref="homeVideo" :autoplay="true" :src="homeItem.conf.index_video" class="video"
				:muted="isMuted"></video>
			<img src="../assets/img/play.png" class="img-play" v-show="!isPlaying">
			<img src="../assets/img/laba2.png" class="img-laba" v-if="isMuted" @click.stop="isMuted=false">
			<img src="../assets/img/laba1.png" class="img-laba" v-else @click.stop="isMuted=true">
		</div>
		<!-- 卡丁车 -->
		<div class="part1">
			<div class="row-me center-all">
				<img src="../assets/img/title_line.png" class="img-line">
				<div class="column-me row-center margin-left10 margin-right10">
					<div><span class="title1">卡丁</span><span class="title2">车</span></div>
					<div class="subtitle">GO-KART</div>
				</div>
				<img src="../assets/img/title_line.png" class="img-line">
			</div>
			<div class="column-me center-all margin-top50">
				<div class="center-div column-me">
					<div class="row-me center-all flex-wrap">
						<div class="tab-div posRelative" :class="{'tab-div-active':tabIndex===index}"
							v-for="(item,index) in tabArray" :key="index" @click="clickTab(item,index)">
							<img :src="item.img" class="posAbsoluteAll">
							<div class="posAbsoluteAll tab-mask"></div>
							<div class="tab-title">{{item.name}}</div>
						</div>
					</div>
				</div>
				<div class="column-me center-all yellow-div">
					<div class="title">{{selectItem.name}}</div>
					<div class="content">
						{{selectItem.content}}
					</div>
					<div class="row-me center-all margin-bottom50">
						<div class="line"></div>
						<div class="number">{{selectItem.id}}</div>
						<div class="line"></div>
					</div>
				</div>
				<img :src="selectItem.img" class="img-car">
				<img src="../assets/img/more1.png" class="img-more" @click="clickParkMore">
			</div>
		</div>
		<!-- 门店展示 -->
		<div class="part2 column-me row-center">
			<div class="row-me center-all">
				<img src="../assets/img/title_line.png" class="img-line">
				<div class="column-me row-center margin-left10 margin-right10">
					<div><span class="title1">部分门店</span><span class="title2">展示</span></div>
					<div class="subtitle">SHOP DISPLAY</div>
				</div>
				<img src="../assets/img/title_line.png" class="img-line">
			</div>
			<div class="row-me center-all" v-if="false">
				<div class="subtitle margin-top20">共{{homeItem.shopCount}}门店 排序不分先后</div>
			</div>
			<div class="row-me row-center margin-top50 flex-wrap">
				<div class="item-show row-me row-center" v-for="(item,index) in homeItem.shops" :key="index"
					@click="clickStore(item)">
					<el-image :src="item.img" fit="cover" class="item-img img-round">
						<template #error>
							<img src="../assets/img/default.png" slot="error"
								class="width100Per height100Per objFitCover">
						</template>
					</el-image>
					<div class="column-me margin-left15 flex1">
						<div class="title1">{{item.name}}</div>
						<div class="title2">{{item.address}}</div>
					</div>
					<div class="height100Per">
						<div class="look-div">查看</div>
					</div>
				</div>
			</div>
			<img src="../assets/img/more2.png" class="img-more anim-bigger-small" @click="clickStoreMore">
		</div>
		<!-- 加盟中心 -->
		<div class="part3">
			<div class="row-me center-all">
				<img src="../assets/img/title_line2.png" class="img-line">
				<div class="column-me row-center margin-left10 margin-right10">
					<div><span class="title11">加盟</span><span class="title22">中心</span></div>
					<div class="subtitle1">FRANCHISE CENTER</div>
				</div>
				<img src="../assets/img/title_line2.png" class="img-line">
			</div>
			<div class="row-me center-all">
				<div class="subtitle2 margin-top40">专业卡丁车运营机构 全程全方位运营支持</div>
			</div>
			<div class="row-me center-all flex-wrap margin-top60">
				<div class="item-meng anim-bigger-small" :class="{'item-meng-active':tabIndex1===index}"
					v-for="(item,index) in mengArray" :key="index" @click="clickTab1(index)">
					{{item.name}}
				</div>
			</div>
			<div class="row-me center-all flex-wrap margin-top60 card-div pointer">
				<div v-for="(item,index) in mengArray" :key="index" class="item-meng1-div">
					<div class="item-meng1">
						<img :src="item.img" class="posAbsoluteAll" @click="tabIndex2=index">
						<transition name="el-zoom-in-bottom">
							<div class="posAbsoluteAll column-me" v-if="tabIndex2===index" @click="tabIndex2=-1">
								<div class="column-me flex1 padding-left20 padding-top40"
									style="background: rgba(40, 40, 40, 0.8);">
									<div class="card-title">{{item.english}}</div>
									<div class="card-line"></div>
								</div>
								<div class="card-btn">{{item.name}}</div>
							</div>
						</transition>
					</div>
				</div>
			</div>
		</div>
		<!-- 新闻动态 -->
		<div class="part4">
			<div class="row-me center-all">
				<img src="../assets/img/title_line.png" class="img-line">
				<div class="column-me row-center margin-left10 margin-right10">
					<div><span class="title1">新闻</span><span class="title2">动态</span></div>
					<div class="subtitle">NEWS</div>
				</div>
				<img src="../assets/img/title_line.png" class="img-line">
			</div>
			<div class="row-me center-all">
				<div class="subtitle margin-top30">新鲜资讯，行业动态皆由此展现</div>
			</div>
			<div class="column-me row-center margin-top50">
				<div class="item-news column-me margin-bottom20" v-for="(item,index) in homeItem.news" :key="index"
					@click="clickNews(item)">
					<el-image :src="item.cover" fit="cover" class="img-news">
						<template #error>
							<img src="../assets/img/default.png" slot="error"
								class="width100Per height100Per objFitCover">
						</template>
					</el-image>
					<div class="column-me padding20">
						<div class="news-title line1">{{item.title}}</div>
						<div class="news-date margin-top10">{{item.create_time}}</div>
						<div class="news-content line2 margin-top30" v-html="item.content"></div>
					</div>
				</div>
			</div>
			<div class="row-me center-all">
				<img src="../assets/img/more2.png" class="img-more anim-bigger-small" @click="clickNewsMore">
			</div>
		</div>
		<!-- 门店地址弹窗 -->
		<el-dialog :title="selectStore.name" v-model="dialogStoreVisible" width="90%">
			<div class="map-div" id="container"></div>
		</el-dialog>
	</div>
	<!-- 电脑端 -->
	<div class="home" v-cloak v-if="!isMobile&&homeItem">
		<div class="video-div" @click="clickVideo">
			<video ref="homeVideo" :autoplay="true" :src="homeItem.conf.index_video" class="video"
				:muted="isMuted"></video>
			<img src="../assets/img/play.png" class="img-play" v-show="!isPlaying">
			<img src="../assets/img/laba2.png" class="img-laba anim-bigger-small" v-if="isMuted"
				@click.stop="isMuted=false">
			<img src="../assets/img/laba1.png" class="img-laba anim-bigger-small" v-else @click.stop="isMuted=true">
		</div>
		<!-- 卡丁车 -->
		<div class="part1">
			<div class="row-me center-all">
				<img src="../assets/img/title_line.png" class="img-line">
				<div class="column-me row-center margin-left60 margin-right60">
					<div><span class="title1">卡丁车</span><span class="title2">车</span></div>
					<div class="subtitle">GO-KART</div>
				</div>
				<img src="../assets/img/title_line.png" class="img-line">
			</div>
			<div class="column-me center-all posRelative margin-top90">
				<div class="center-div column-me">
					<div class="row-me row-center flex-end">
						<div class="tab-div posRelative" :class="{'tab-div-active':tabIndex===index}"
							v-for="(item,index) in tabArray" :key="index" @click="clickTab(item,index)">
							<img :src="item.img" class="posAbsoluteAll">
							<div class="posAbsoluteAll tab-mask"></div>
							<div class="tab-title">{{item.name}}</div>
						</div>
					</div>
					<img :src="selectItem.img" class="img-car">
				</div>
				<div class="column-me center-all yellow-div">
					<div class="title">{{selectItem.name}}</div>
					<div class="content">
						{{selectItem.content}}
					</div>
					<div class="row-me center-all margin-bottom50">
						<div class="line"></div>
						<div class="number">{{selectItem.id}}</div>
						<div class="line"></div>
					</div>
				</div>
				<img src="../assets/img/more1.png" class="img-more anim-bigger-small" @click="clickParkMore">
			</div>
		</div>
		<!-- 门店展示 -->
		<div class="part2 column-me row-center">
			<div class="row-me center-all">
				<img src="../assets/img/title_line.png" class="img-line">
				<div class="column-me row-center margin-left60 margin-right60">
					<div><span class="title1">部分门店</span><span class="title2">展示</span></div>
					<div class="subtitle">SHOP DISPLAY</div>
				</div>
				<img src="../assets/img/title_line.png" class="img-line">
			</div>
			<div class="row-me center-all" v-if="false">
				<div class="subtitle margin-top20">共{{homeItem.shopCount}}门店 排序不分先后</div>
			</div>
			<div class="row-me row-center margin-top80 flex-wrap">
				<div class="item-show row-me row-center" v-for="(item,index) in homeItem.shops" :key="index"
					@click="clickStore(item)">
					<el-image :src="item.img" fit="cover" class="width100 height100 img-round margin-left30">
						<template #error>
							<img src="../assets/img/default.png" slot="error"
								class="width100Per height100Per objFitCover">
						</template>
					</el-image>
					<div class="column-me margin-left30 flex1">
						<div class="title1">{{item.name}}</div>
						<div class="title2">{{item.address}}</div>
					</div>
					<div class="height100Per">
						<div class="look-div">查看</div>
					</div>
				</div>
			</div>
			<img src="../assets/img/more2.png" class="img-more anim-bigger-small" @click="clickStoreMore">
		</div>
		<!-- 加盟中心 -->
		<div class="part3">
			<div class="row-me center-all">
				<img src="../assets/img/title_line2.png" class="img-line">
				<div class="column-me row-center margin-left60 margin-right60">
					<div><span class="title11">加盟</span><span class="title22">中心</span></div>
					<div class="subtitle1">FRANCHISE CENTER</div>
				</div>
				<img src="../assets/img/title_line2.png" class="img-line">
			</div>
			<div class="row-me center-all">
				<div class="subtitle2 margin-top40">专业卡丁车运营机构 全程全方位运营支持</div>
			</div>
			<div class="row-me center-all margin-top60">
				<div class="item-meng anim-bigger-small" :class="{'item-meng-active':tabIndex1===index}"
					v-for="(item,index) in mengArray" :key="index" @click="clickTab1(index)">
					{{item.name}}
				</div>
			</div>
			<div class="row-me row-center margin-top60 card-div pointer" v-dragscroll.x="true">
				<div v-for="(item,index) in mengArray" :key="index" class="item-meng1-div">
					<div class="item-meng1">
						<img :src="item.img" class="posAbsoluteAll" @mouseenter="tabIndex2=index">
						<transition name="el-zoom-in-bottom">
							<div class="posAbsoluteAll column-me" v-if="tabIndex2===index" @mouseleave="tabIndex2=-1">
								<div class="column-me flex1 padding-left20 padding-top40"
									style="background: rgba(40, 40, 40, 0.8);">
									<div class="card-title">{{item.english}}</div>
									<div class="card-line"></div>
								</div>
								<div class="card-btn">{{item.name}}</div>
							</div>
						</transition>
					</div>
				</div>
			</div>
		</div>
		<!-- 新闻动态 -->
		<div class="part4">
			<div class="row-me center-all">
				<img src="../assets/img/title_line.png" class="img-line">
				<div class="column-me row-center margin-left60 margin-right60">
					<div><span class="title1">新闻</span><span class="title2">动态</span></div>
					<div class="subtitle">NEWS</div>
				</div>
				<img src="../assets/img/title_line.png" class="img-line">
			</div>
			<div class="row-me center-all">
				<div class="subtitle margin-top50">新鲜资讯，行业动态皆由此展现</div>
			</div>
			<div class="row-me row-center flex-sb margin-top100">
				<div class="item-news column-me" v-for="(item,index) in homeItem.news" :key="index"
					@click="clickNews(item)">
					<el-image :src="item.cover" fit="cover" class="img-news">
						<template #error>
							<img src="../assets/img/default.png" slot="error"
								class="width100Per height100Per objFitCover">
						</template>
					</el-image>
					<div class="column-me padding40">
						<div class="news-title line1">{{item.title}}</div>
						<div class="news-date margin-top10">{{item.create_time}}</div>
						<div class="news-content line2 margin-top50" v-html="item.content"></div>
					</div>
				</div>
			</div>
			<div class="row-me center-all">
				<img src="../assets/img/more2.png" class="img-more anim-bigger-small" @click="clickNewsMore">
			</div>
		</div>
		<!-- 门店地址弹窗 -->
		<el-dialog :title="selectStore.name" v-model="dialogStoreVisible">
			<div class="map-div" id="container"></div>
		</el-dialog>
	</div>
</template>

<script>
	import AMap from 'AMap' // 引入高德地图
	let interval;
	export default {
		components: {

		},
		data() {
			return {
				tabArray: [],
				tabIndex: 0,
				tabIndex1: 0,
				tabIndex2: -1,
				selectItem: {},
				mengArray: [], //加盟中心
				isPlaying: false,
				homeItem: null,
				dialogStoreVisible: false,
				selectStore: {}, //点击的门店
				isMuted: true, //视频是否静音
				isMobile: false,
			}
		},
		// html加载完成之前
		created() {
			let temp = []
			temp.push({
				id: '01',
				name: '竞速馆',
				img: require('../assets/img/jingsuguan.png'),
				content: '面积：300平左右  儿童卡丁车常见场馆模式  以驾驶体验为主'
			})
			temp.push({
				id: '02',
				name: '运动公园',
				img: require('../assets/img/zhutileyuan.png'),
				content: '面积：1000平左右  融合多种经营业态  客流容纳量大  运营项目多'
			})
			temp.push({
				id: '03',
				name: '室外运动场',
				img: require('../assets/img/huwai.png'),
				content: '面积：300平以上  场地面积大  驾驶体验效果好  客流容纳量大'
			})
			// temp.push({
			// 	id: '04',
			// 	name: '教培馆',
			// 	img: require('../assets/img/jiaopeiguan.png'),
			// 	content: '面积：300--500平  充分融合驾驶体验和训练课程  客单价较高'
			// })
			// temp.push({
			// 	id: '05',
			// 	name: '体能拓展馆',
			// 	img: require('../assets/img/tinengguan.png'),
			// 	content: '面积：500--1000平  突出训练和拓展课程盈利形式灵活'
			// })
			this.tabArray = temp
			if (this.tabArray.length > 0) {
				this.selectItem = this.tabArray[0]
			}
			let temp1 = []
			temp1.push({
				name: '0元加盟',
				english: 'ZERO TO JOIN',
				img: require('../assets/img/meng/img4.png')
			})
			temp1.push({
				name: '全套设备',
				english: 'COMPLETE EQUIPMENT',
				img: require('../assets/img/meng/img5.png')
			})
			temp1.push({
				name: '课程体系',
				english: 'COURSE STRUCTURE',
				img: require('../assets/img/meng/img6.png')
			})
			temp1.push({
				name: '赛事组织',
				english: 'EVENT ORGANIZATION',
				img: require('../assets/img/meng/img7.png')
			})
			temp1.push({
				name: '运营赋能',
				english: 'OPERAT EMPOWERMENT',
				img: require('../assets/img/meng/img8.png')
			})
			this.mengArray = temp1
			this.getHomeData()
		},
		// html加载完成后执行。
		mounted() {
			this.isMobile = sessionStorage.getItem('mobile') === '1'
		},
		// 事件方法执行
		methods: {
			//卡丁车查看更多
			clickParkMore() {
				this.$router.push({
					path: '/sport_park',
					query: {
						index: this.tabIndex
					}
				})
			},
			//门店展示查看更多
			clickStoreMore() {
				this.$router.push({
					path: '/about_us',
					query: {
						index: 2
					}
				})
			},
			//新闻动态查看更多
			clickNewsMore() {
				this.$router.push({
					path: '/about_us',
					query: {
						index: 3
					}
				})
			},
			//新闻点击
			clickNews(item) {
				this.$router.push({
					path: '/detail',
					query: {
						id: item.id
					}
				})
			},
			//视频点击
			clickVideo() {
				if (this.isPlaying) {
					this.$refs.homeVideo.pause()
					this.isPlaying = false
					if (interval) {
						clearInterval(interval)
					}
				} else {
					this.$refs.homeVideo.play()
					this.isPlaying = true
					this.listnerVideo()
				}
			},
			listnerVideo() {
				let self = this
				if (interval) {
					clearInterval(interval)
				}
				interval = setInterval(() => {
					if (self.$refs.homeVideo && self.$refs.homeVideo.ended) {
						if (interval) {
							clearInterval(interval)
						}
						self.isPlaying = false
					}
				}, 500)
			},
			clickTab(item, index) {
				this.selectItem = item
				this.tabIndex = index
			},
			clickTab1(index) {
				this.tabIndex1 = index
				this.tabIndex2 = index
			},
			//门店点击
			clickStore(item) {
				let that = this
				this.selectStore = item
				this.dialogStoreVisible = true
				this.$nextTick(() => {
					if (this.isIos()) {
						this.initStoreMapIos(item)
					} else {
						this.initStoreMap(item)
					}
				})
			},
			//初始化门店地图
			initStoreMap(item) {
				var map = new AMap.Map('container', {
					resizeEnable: true,
					center: [Number(item.longitude), Number(item.latitude)],
					zoom: 17,
					viewMode: '3D' //使用3D视图
				});
				var marker = new AMap.Marker({
					position: [Number(item.longitude), Number(item.latitude)], //位置
					title: item.name,
					icon: require('../assets/img/position.png'),
					offset: new AMap.Pixel(-30, -40)
				})
				map.add(marker); //添加到地图
				let content =
					'<div class="map-info-window-content row-me center-all">' +
					'<div class="map-company-name">' + item.name + '</div>' +
					'</div>';
				if (this.isMobile) {
					var infoWindow = new AMap.InfoWindow({
						isCustom: true, //使用自定义窗体
						showShadow: true,
						content: content,
						offset: new AMap.Pixel(-10, 120)
					});
					infoWindow.open(map, marker.getPosition());
				} else {
					var infoWindow = new AMap.InfoWindow({
						isCustom: true, //使用自定义窗体
						showShadow: true,
						content: content,
						offset: new AMap.Pixel(-10, 60)
					});
					infoWindow.open(map, marker.getPosition());
				}
			},
			initStoreMapIos(item) {
				var map = new AMap.Map('container', {
					resizeEnable: true,
					center: [Number(item.longitude), Number(item.latitude)],
					zoom: 19,
					viewMode: '3D' //使用3D视图
				});
				var marker = new AMap.Marker({
					position: [Number(item.longitude), Number(item.latitude)], //位置
					title: item.name,
					icon: new AMap.Icon({
						image: require('../assets/img/position.png'),
						size: new AMap.Size(170, 170), //图标所处区域大小
						imageSize: new AMap.Size(170, 170) //图标大小
					}),
					offset: new AMap.Pixel(-100, -200)
				})
				map.add(marker); //添加到地图
				let content =
					'<div class="map-info-window-content row-me center-all">' +
					'<div class="map-company-name">' + item.name + '</div>' +
					'</div>';
				if (this.isMobile) {
					var infoWindow = new AMap.InfoWindow({
						isCustom: true, //使用自定义窗体
						showShadow: true,
						content: content,
						offset: new AMap.Pixel(-10, 350)
					});
					infoWindow.open(map, marker.getPosition());
				} else {
					var infoWindow = new AMap.InfoWindow({
						isCustom: true, //使用自定义窗体
						showShadow: true,
						content: content,
						offset: new AMap.Pixel(-10, 60)
					});
					infoWindow.open(map, marker.getPosition());
				}
			},
			//是否苹果手机
			isIos() {
				let ua = navigator.userAgent.toLowerCase()
				return ua.indexOf('iphone') !== -1
			},
			//获取数据
			getHomeData() {
				let self = this
				this.$postRequest('/api/index/index', {}).then((res) => {
					if (res) {
						this.homeItem = res.data
						this.$nextTick(() => {
							self.$refs.homeVideo.play()
							self.isPlaying = true
							self.listnerVideo()
						})
					}
				}).catch((error) => {
					console.log(error)
				})
			}
		},
		// 计算属性
		computed: {},
		// 侦听器
		watch: {},
		destroyed() {
			if (interval) {
				clearInterval(interval)
			}
		},
		//页面显示
		activated() {
			this.getHomeData()
		},
		//页面隐藏
		deactivated: function() {
			this.isPlaying = false
			if (interval) {
				clearInterval(interval)
			}
		},
	}
</script>

<style lang="scss" scoped>
	.home-mobile {
		.video-div {
			width: 100%;
			height: auto;
			position: relative;

			.video {
				width: 100%;
				height: auto;
			}

			.img-play {
				width: 60px;
				height: 60px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translateX(-50%) translateY(-50%);
				cursor: pointer;
			}

			.img-laba {
				width: 30px;
				height: 25px;
				position: absolute;
				bottom: 10%;
				right: 7%;
				cursor: pointer;
			}
		}

		.title1 {
			font-size: 35px;
			color: #000000;
			letter-spacing: 5px;
		}

		.title11 {
			font-size: 35px;
			color: #FFFFFF;
			letter-spacing: 5px;
		}

		.title2 {
			font-size: 35px;
			color: #F4E712;
			letter-spacing: 5px;
		}

		.title22 {
			font-size: 35px;
			color: #F4E712;
			letter-spacing: 5px;
		}

		.subtitle {
			font-size: 18px;
			color: #666666;
		}

		.subtitle1 {
			font-size: 18px;
			color: #999999;
		}

		.subtitle2 {
			font-size: 18px;
			color: #FFFFFF;
		}

		.img-line {
			width: 80px;
		}

		.part1 {
			padding-top: 50px;

			.img-more {
				width: 120px;
				height: 40px;
				cursor: pointer;
			}

			.yellow-div {
				width: 100%;
				margin-top: 50px;
				background-color: rgba(255, 241, 12, 0.9);

				.title {
					font-size: 33px;
					font-weight: bold;
					color: #000000;
					margin-top: 60px;
				}

				.content {
					width: 335px;
					flex: 1;
					line-height: 40px;
					margin-top: 50px;
					margin-bottom: 40px;
					font-size: 24px;
					font-weight: 400;
					color: #000000;
					overflow: auto;
				}

				.number {
					font-size: 48px;
					font-weight: bold;
					color: #000000;
					margin: 0 10px;
				}

				.line {
					width: 50px;
					height: 5px;
					background: #000000;
					border-radius: 30px;
				}
			}

			.center-div {
				width: 100%;

				.tab-div {
					width: 160px;
					height: 70px;
					margin-right: 10px;
					margin-bottom: 10px;
					cursor: pointer;

					&-active {
						width: 150px;
						height: 60px;
						border: 5px solid #E60012;
					}

					.tab-mask {
						background-color: rgba(#000000, 0.5);
					}

					.tab-title {
						left: 0;
						top: 25%;
						width: 100%;
						height: 100%;
						font-size: 20px;
						color: #FFFFFF;
						position: absolute;
						text-align: center;
					}
				}
			}

			.img-car {
				width: 100%;
				object-fit: cover;
				margin-bottom: 50px;
				margin-top: 20px;
			}
		}

		.part2 {
			padding-top: 60px;
			padding-bottom: 60px;

			.img-more {
				width: 150px;
				height: 30px;
				margin-top: 50px;
				cursor: pointer;
			}

			.item-show {
				width: 100%;
				height: 150px;
				background: #F6F6F6;
				margin-bottom: 20px;
				cursor: pointer;

				&:hover {
					background: #FFE100;

					.look-div {
						background-color: #000000;
						color: #FFFFFF;
					}
				}

				&:nth-child(2n) {
					margin-right: 0;
				}

				.item-img {
					width: 80px;
					height: 80px;
					margin-left: 10px;
				}

				.title1 {
					font-size: 22px;
					color: #000000;
					font-weight: bold;
				}

				.title2 {
					font-size: 18px;
					color: #000000;
					margin-top: 10px;
				}

				.look-div {
					width: 60px;
					height: 30px;
					background: #FFE100;
					line-height: 30px;
					text-align: center;
					font-size: 18px;
					color: #000000;
					margin-top: 33px;
					margin-right: 10px;
				}
			}
		}

		.part3 {
			padding: 50px 0;
			background: #222222;

			.item-meng {
				width: 150px;
				height: 50px;
				line-height: 50px;
				text-align: center;
				border: 1px solid #FFFFFF;
				border-radius: 30px;
				font-size: 22px;
				color: #FFFFFF;
				margin: 10px 15px;
				cursor: pointer;

				&-active {
					border: 1px solid #FBE34C;
					color: #FBE34C;
				}
			}

			.card-div {
				width: 100%;
				overflow: auto;
			}

			.item-meng1-div {
				margin-bottom: 10px;

				&:nth-last-child {
					margin-right: 0;
				}
			}

			.item-meng1 {
				width: 380px;
				height: 320px;
				position: relative;

				.card-title {
					font-size: 20px;
					color: #FFFFFF;
				}

				.card-line {
					width: 80px;
					height: 2px;
					background: #FBE34C;
					margin-top: 15px;
				}

				.card-btn {
					width: 380px;
					height: 80px;
					line-height: 80px;
					text-align: center;
					background: #FBE34C;
					font-size: 28px;
					color: #282828;
				}
			}
		}

		.part4 {
			width: 100%;
			padding: 60px 0;

			.item-news {
				width: 100%;
				height: fit-content;
				background: #FFFFFF;
				box-shadow: 0px 10px 30px 0px rgba(40, 40, 40, 0.1);
				cursor: pointer;

				&:hover {
					background: #FADA0F;
				}

				.img-news {
					height: 250px;
					object-fit: cover;
				}

				.news-title {
					font-size: 23px;
					font-weight: bold;
					color: #222222;
				}

				.news-date {
					font-size: 18px;
					color: #222222;
				}

				.news-content {
					font-size: 20px;
					color: #222222;
					line-height: 30px;
				}
			}

			.img-more {
				width: 140px;
				height: 35px;
				margin-top: 50px;
				cursor: pointer;
			}
		}

		.map-div {
			width: 100%;
			height: 50vh;
		}
	}

	.home {
		.video-div {
			width: 100%;
			height: 790px;
			position: relative;

			.video {
				width: 100%;
				height: 100%;
				object-fit: fill;
			}

			.img-play {
				width: 120px;
				height: 120px;
				position: absolute;
				bottom: 30%;
				left: 50%;
				transform: translateX(-50%);
				cursor: pointer;
				z-index: 9999;
			}

			.img-laba {
				width: 60px;
				height: 60px;
				position: absolute;
				bottom: 5%;
				right: 7%;
				object-fit: scale-down;
				cursor: pointer;
				z-index: 9999;
			}
		}

		.title1 {
			font-size: 48px;
			color: #000000;
			letter-spacing: 5px;
		}

		.title11 {
			font-size: 48px;
			color: #FFFFFF;
			letter-spacing: 5px;
		}

		.title2 {
			font-size: 48px;
			color: #F4E712;
			letter-spacing: 5px;
		}

		.title22 {
			font-size: 48px;
			color: #F4E712;
			letter-spacing: 5px;
		}

		.subtitle {
			font-size: 24px;
			color: #666666;
		}

		.subtitle1 {
			font-size: 24px;
			color: #999999;
		}

		.subtitle2 {
			font-size: 24px;
			color: #FFFFFF;
		}

		.img-line {
			width: 145px;
			object-fit: scale-down;
			margin-bottom: 10px;
		}

		.part1 {
			padding-top: 100px;

			.img-more {
				position: absolute;
				right: 0;
				bottom: 0;
				width: 300px;
				height: 80px;
				object-fit: scale-down;
				cursor: pointer;
				margin-right: 100px;
			}

			.yellow-div {
				width: 500px;
				height: 550px;
				background-color: rgba(255, 241, 12, 0.9);
				position: absolute;
				left: 100px;
				top: 0;

				.title {
					font-size: 36px;
					font-weight: bold;
					color: #000000;
					margin-top: 60px;
				}

				.content {
					width: 335px;
					flex: 1;
					line-height: 40px;
					margin-top: 50px;
					margin-bottom: 40px;
					font-size: 24px;
					font-weight: 400;
					color: #000000;
					overflow: auto;
				}

				.number {
					font-size: 48px;
					font-weight: bold;
					color: #000000;
					margin: 0 10px;
				}

				.line {
					width: 50px;
					height: 5px;
					background: #000000;
					border-radius: 30px;
				}
			}

			.center-div {
				width: 1440px;

				.tab-div {
					width: 180px;
					height: 70px;
					margin-left: 10px;
					cursor: pointer;

					&-active {
						width: 170px;
						height: 60px;
						border: 5px solid #E60012;
					}

					.tab-mask {
						background-color: rgba(#000000, 0.5);
					}

					.tab-title {
						left: 0;
						top: 25%;
						width: 100%;
						height: 100%;
						font-size: 26px;
						color: #FFFFFF;
						position: absolute;
						text-align: center;
					}
				}

				.img-car {
					width: 100%;
					height: 550px;
					object-fit: cover;
					margin-top: 68px;
					margin-bottom: 50px;
				}
			}
		}

		.part2 {
			padding-top: 120px;
			padding-bottom: 120px;
			margin: 0 100px;

			.img-more {
				width: 320px;
				height: 60px;
				object-fit: scale-down;
				margin-top: 80px;
				cursor: pointer;
			}

			.item-show {
				width: 844px;
				height: 150px;
				background: #F6F6F6;
				margin-right: 20px;
				margin-bottom: 20px;
				cursor: pointer;

				&:hover {
					background: #FFE100;

					.look-div {
						background-color: #000000;
						color: #FFFFFF;
					}
				}

				&:nth-child(2n) {
					margin-right: 0;
				}

				.title1 {
					font-size: 30px;
					color: #000000;
					font-weight: bold;
				}

				.title2 {
					font-size: 24px;
					color: #000000;
					margin-top: 10px;
				}

				.look-div {
					width: 90px;
					height: 36px;
					background: #FFE100;
					line-height: 36px;
					text-align: center;
					font-size: 24px;
					color: #000000;
					margin-top: 33px;
					margin-right: 33px;
				}
			}
		}

		.part3 {
			padding: 100px;
			background: #222222;

			.item-meng {
				width: 180px;
				height: 60px;
				line-height: 60px;
				text-align: center;
				border: 1px solid #FFFFFF;
				border-radius: 30px;
				font-size: 24px;
				color: #FFFFFF;
				margin: 0 15px;
				cursor: pointer;

				&-active {
					border: 1px solid #FBE34C;
					color: #FBE34C;
				}
			}

			.card-div {
				width: 100%;
				overflow: auto;
			}

			.item-meng1-div {
				margin-right: 28px;

				&:nth-last-child {
					margin-right: 0;
				}
			}

			.item-meng1 {
				width: 320px;
				height: 320px;
				position: relative;

				.card-title {
					font-size: 20px;
					color: #FFFFFF;
				}

				.card-line {
					width: 80px;
					height: 2px;
					background: #FBE34C;
					margin-top: 15px;
				}

				.card-btn {
					width: 320px;
					height: 80px;
					line-height: 80px;
					text-align: center;
					background: #FBE34C;
					font-size: 28px;
					color: #282828;
				}
			}
		}

		.part4 {
			padding: 100px;

			.item-news {
				width: 554px;
				height: 760px;
				background: #FFFFFF;
				box-shadow: 0px 10px 30px 0px rgba(40, 40, 40, 0.1);
				cursor: pointer;

				&:hover {
					background: #FADA0F;
				}

				.img-news {
					height: 480px;
					object-fit: cover;
				}

				.news-title {
					font-size: 30px;
					font-weight: bold;
					color: #222222;
				}

				.news-date {
					font-size: 20px;
					color: #222222;
				}

				.news-content {
					font-size: 20px;
					color: #222222;
					line-height: 30px;
				}
			}

			.img-more {
				width: 320px;
				height: 60px;
				object-fit: scale-down;
				margin-top: 80px;
				cursor: pointer;
			}
		}

		.map-div {
			width: 100%;
			height: 60vh;
		}
	}
</style>
